import { CapOneIcons } from './cl/icons/capone';
import { CitizensIcons } from './cl/icons/citizens';
import { CloverIcons } from './cl/icons/clover';
import { FiservIcons } from './cl/icons/fiserv';
import { MelioIcons } from './cl/icons/melio';
import { PayPalIcons } from './cl/icons/paypal';
import { UsBankIcons } from './cl/icons/usbank';
import { WafedIcons } from './cl/icons/wafed';
import {
  capOneTheme,
  citizensTheme,
  cloverTheme,
  fiservTheme,
  melioTheme,
  usBankTheme,
  wafedTheme,
} from './cl/theme/partners';
import { gustoIcons, gustoTheme } from './cl/theme/partners/gusto';
import { payPalTheme } from './cl/theme/partners/paypal';
export { _defaultConfig } from './configByPartner/default.config';
export { DEFAULT_CONFIG_KEY, DEV_CONFIG_KEY, mergeConfigCustomizer } from './configByPartner/partnerConfig.utils';

import {
  fiservFirstFinancialConfig,
  fiservFirstFinancialConfigBase,
} from './configByPartner/fiservFirstFinancial.config';
import { gustoByEnvConfig, gustoConfig } from './configByPartner/gusto.config';
import { PartnerConfigByEnv } from './configByPartner/partnerConfig.utils';
import { sbbConfig, sbbEnvConfig } from './configByPartner/sbb.config';
import {
  capitalOneConfig,
  capOneEnvConfig,
  cloverConfig,
  cloverEnvConfig,
  fiservCitizensConfig,
  fiservCitizensConfigBase,
  fiservConfig,
  fiservConfigBase,
  fiservUsBankConfig,
  fiservUsBankConfigBase,
  fiservWafedConfig,
  fiservWafedConfigBase,
  fiservXDDemoConfigBase,
  melioConfig,
  melioPartialConfig,
  paypalConfig,
  paypalEnvConfig,
} from './configByPartner';
import { Features, PartnerConfig, PartnersConfig } from './partnersConfig.types';
export const PartnerEnvConfig: Record<keyof PartnersConfig, PartnerConfigByEnv | null> = {
  melio: melioPartialConfig,
  'capital-one': capOneEnvConfig,
  clover: cloverEnvConfig,
  fiserv: fiservConfigBase,
  'fiserv_xd-demo': fiservXDDemoConfigBase,
  'fiserv_us-bank': fiservUsBankConfigBase,
  'fiserv_first-financial': fiservFirstFinancialConfigBase,
  fiserv_citizens: fiservCitizensConfigBase,
  'fiserv_citizens-private-bank': fiservCitizensConfigBase,
  fiserv_wafed: fiservWafedConfigBase,
  'deployments-demo': fiservUsBankConfigBase,
  gusto: gustoByEnvConfig,
  paypal: paypalEnvConfig,
  sbb: sbbEnvConfig,
  shopify: null,
  'wells-fargo': null,
  'optum-bank-demo': null,
  'built-technologies': null,
};

export const PartnersConfiguration: PartnersConfig = {
  melio: {
    displayName: 'Melio',
    partnerNameAnalyticsEvents: 'melio',
    partnerProductName: 'Melio',
    theme: melioTheme,
    config: melioConfig,
    icons: MelioIcons,
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
        orgId: 'orgId',
        userId: 'userId',
        terms: 'terms',
        mock: 'mock',
        target: 'target',
        targetId: 'targetId',
        entryPoint: 'entryPoint',
        redirectUrl: 'redirect_url',
      },
    },
    rolesMapping: {
      ['melio-owner']: 1,
      ['melio-admin']: 2,
      ['melio-accountant']: 3,
      ['melio-contributor']: 4,
    },
    supportEmail: 'support@meliopayments.com',
    showOnboardingSwiper: true,
    allowEditCompanyDetails: true,
    allowEditFirstAndLastName: true,
    hideFooter: true,
    skipLogoutSuccessful: true,
    features: {
      [Features.GetPaid]: {
        enabled: true,
      },
      [Features.AccountingFirm]: {
        enabled: true,
      },
      [Features.Expenses]: {
        enabled: true,
      },
      [Features.ReceivingMethods]: {
        learnMoreUrl: {
          zendeskLink:
            'https://melio.zendesk.com/hc/en-us/articles/4416227178908-How-can-I-pay-the-credit-card-fee-on-behalf-of-my-customers',
          melioPaymentsLink:
            'https://help.meliopayments.com/en/articles/5187223-how-can-i-pay-the-credit-card-fee-on-behalf-of-my-customer',
        },
      },
    },
    supportLegacyOrganizations: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: true,
      approvalByOtherActions: true,
      deliveryMethodCreatedUpdated: true,
      accountantsPeriodicSummary: true,
    },
  },
  'capital-one': {
    displayName: 'Capital One Business',
    partnerNameAnalyticsEvents: 'capital-one',
    partnerProductName: 'Accounts Payable',
    theme: capOneTheme,
    config: capitalOneConfig,
    icons: CapOneIcons,
    supportEmail: 'capitaloneaccountspayable@melio.com',
    supportQuestionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'assertion',
        orgId: 'id',
        terms: 'acceptedTerms',
        target: 'target',
        targetId: 'targetId',
        entryPoint: 'entryPoint',
        userRole: 'userRole',
        backButtonReferenceId: 'id',
      },
    },
    rolesMapping: {
      ['capital-one-primary']: 1,
      ['capital-one-am']: 2,
    },
    showOnboardingSwiper: true,
    showOnboardingBackToPartner: true,
    billsInbox: {
      learnMoreLink: 'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/6482836621852',
      showEmailLinkTitle: false,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: false,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      billQbSynced: true,
      deliveryMethodCreatedUpdated: true,
      accountantsPeriodicSummary: true,
    },
  },
  clover: {
    displayName: 'Clover',
    partnerNameAnalyticsEvents: 'clover',
    partnerProductName: 'Pay Bills',
    tabsVariant: 'neutral',
    theme: cloverTheme,
    config: cloverConfig,
    icons: CloverIcons,
    supportEmail: 'support-billpay@clover.melio.com',
    supportQuestionIds: [1, 2, 4, 5, 6, 3, 7, 8, 9, 10],
    rolesMapping: {
      ['clover-owner']: 1,
      ['clover-admin']: 2,
    },
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'code',
        orgId: 'merchant_id',
        userId: 'employee_id',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: false,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: false,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      deliveryMethodCreatedUpdated: true,
      accountantsPeriodicSummary: true,
    },
  },
  fiserv: {
    displayName: 'Us Bank',
    partnerNameAnalyticsEvents: 'us-bank',
    partnerProductName: 'Pay Bills',
    theme: fiservTheme,
    config: fiservConfig,
    icons: UsBankIcons,
    supportEmail: 'us-bank-payments@melio.com',
    rolesMapping: {
      FiservLevel1: 1,
      FiservLevel2: 2,
      FiservLevel3: 3,
    },
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      showEmailLinkTitle: true,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      deliveryMethodCreatedUpdated: true,
      accountantsPeriodicSummary: true,
    },
  },
  'fiserv_xd-demo': {
    displayName: 'XD demo',
    partnerNameAnalyticsEvents: 'xd-demo-bank',
    partnerProductName: 'Pay Bills',
    theme: fiservTheme,
    config: fiservFirstFinancialConfig,
    icons: FiservIcons,
    supportEmail: 'fiserv-xd-demo@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      showEmailLinkTitle: false,
    },
    rolesMapping: {
      ['fiserv-level-1']: 1,
      ['fiserv-level-2']: 2,
      ['fiserv-level-3']: 3,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      deliveryMethodCreatedUpdated: true,
      accountantsPeriodicSummary: true,
    },
  },
  'fiserv_us-bank': {
    displayName: 'Us Bank',
    partnerNameAnalyticsEvents: 'us-bank',
    partnerProductName: 'Pay Bills',
    theme: usBankTheme,
    config: fiservUsBankConfig,
    icons: UsBankIcons,
    supportEmail: 'us-bank-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      showEmailLinkTitle: true,
    },
    rolesMapping: {
      ['fiserv-level-1']: 1,
      ['fiserv-level-2']: 2,
      ['fiserv-level-3']: 3,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      deliveryMethodCreatedUpdated: true,
      accountantsPeriodicSummary: true,
    },
  },
  'fiserv_first-financial': {
    displayName: 'First Financial',
    partnerNameAnalyticsEvents: 'first-financial',
    partnerProductName: 'Pay Bills',
    theme: fiservTheme,
    config: fiservFirstFinancialConfig,
    icons: FiservIcons,
    supportEmail: 'us-bank-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    rolesMapping: {
      ['fiserv-level-1']: 1,
      ['fiserv-level-2']: 2,
      ['fiserv-level-3']: 3,
    },
    showOnboardingSwiper: false,
    billsInbox: {
      showEmailLinkTitle: true,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      deliveryMethodCreatedUpdated: true,
      accountantsPeriodicSummary: true,
    },
  },
  fiserv_citizens: {
    displayName: 'Citizens',
    partnerNameAnalyticsEvents: 'citizens-bank',
    partnerProductName: 'Pay Bills',
    theme: citizensTheme,
    config: fiservCitizensConfig,
    icons: CitizensIcons,
    supportEmail: 'fiserv_citizens-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      showEmailLinkTitle: true,
    },
    rolesMapping: {
      ['fiserv-level-1']: 1,
      ['fiserv-level-2']: 2,
      ['fiserv-level-3']: 3,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      deliveryMethodCreatedUpdated: true,
      accountantsPeriodicSummary: true,
    },
  } as PartnerConfig,
  'fiserv_citizens-private-bank': {
    displayName: 'Citizens',
    partnerNameAnalyticsEvents: 'citizens-bank',
    partnerProductName: 'Pay Bills',
    theme: citizensTheme,
    config: fiservCitizensConfig,
    icons: CitizensIcons,
    supportEmail: 'fiserv_citizens-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      showEmailLinkTitle: true,
    },
    rolesMapping: {
      ['fiserv-level-1']: 1,
      ['fiserv-level-2']: 2,
      ['fiserv-level-3']: 3,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      deliveryMethodCreatedUpdated: true,
    },
  } as PartnerConfig,
  fiserv_wafed: {
    displayName: 'WaFd Bank',
    partnerNameAnalyticsEvents: 'wafed-bank',
    partnerProductName: 'Pay Bills',
    theme: wafedTheme,
    config: fiservWafedConfig,
    icons: WafedIcons,
    supportEmail: 'fiserv_wafed-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      showEmailLinkTitle: true,
    },
    rolesMapping: {
      ['fiserv-level-1']: 1,
      ['fiserv-level-2']: 2,
      ['fiserv-level-3']: 3,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      deliveryMethodCreatedUpdated: true,
      accountantsPeriodicSummary: true,
    },
  } as PartnerConfig,
  'deployments-demo': {
    displayName: 'Us Bank',
    partnerNameAnalyticsEvents: 'us-bank',
    partnerProductName: 'Pay Bills',
    theme: fiservTheme,
    config: fiservUsBankConfig,
    icons: UsBankIcons,
    supportEmail: 'us-bank-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      showEmailLinkTitle: false,
    },
    rolesMapping: {},
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      deliveryMethodCreatedUpdated: true,
      accountantsPeriodicSummary: true,
    },
  },
  shopify: {
    // shopify is not in use in the platform-app
  } as PartnerConfig,
  'built-technologies': {
    // Built is not in use in the platform-app
  } as PartnerConfig,
  // off-the-shelf partners that we'll eventually move to store the config in db
  gusto: {
    displayName: 'Gusto',
    partnerNameAnalyticsEvents: 'gusto',
    partnerProductName: 'Bill Pay',
    config: gustoConfig,
    theme: gustoTheme,
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
        target: 'target',
      },
    },
    icons: gustoIcons,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: false,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      billQbSynced: true,
      deliveryMethodCreatedUpdated: true,
      accountantsPeriodicSummary: true,
    },
  } as PartnerConfig,
  paypal: {
    displayName: 'PayPal',
    partnerNameAnalyticsEvents: 'paypal',
    partnerProductName: 'Pay bills',
    config: paypalConfig,
    theme: payPalTheme,
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'code',
        target: 'target',
      },
    },
    icons: PayPalIcons,
  } as PartnerConfig,
  'wells-fargo': {
    // ?????
  } as PartnerConfig,
  sbb: {
    displayName: 'Capital One Business',
    partnerNameAnalyticsEvents: 'sbb',
    partnerProductName: 'Pay Business Bills',
    theme: capOneTheme,
    config: sbbConfig,
    icons: CapOneIcons,
    supportEmail: 'support@capitalonebusinessbills.melio.com',
    supportQuestionIds: [14, 1, 5, 2, 13, 8, 10, 9, 3, 11, 12],
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'assertion',
        orgId: 'bid',
        terms: 'acceptedTerms',
        target: 'target',
        targetId: 'targetId',
        entryPoint: 'entryPoint',
        backButtonReferenceId: 'id',
      },
    },
    rolesMapping: {
      ['capital-one-primary']: 1,
      ['capital-one-am']: 2,
    },
    showOnboardingSwiper: true,
    billsInbox: {
      learnMoreLink:
        'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702494960668-How-to-automatically-receive-bills-to-your-Bills-inbox',
      showEmailLinkTitle: false,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: false,
    supportedNotificationGroupsOptions: {
      paymentByMeScheduled: false,
      paymentByMeUpdated: false,
      billDueReminder: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      deliveryMethodCreatedUpdated: true,
      accountantsPeriodicSummary: true,
    },
  },
};
