import { PartialMelioConfig } from '@melio/platform-provider';

import { melioLinks } from '../links';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';

const defaultMelioConfig: PartialMelioConfig = {
  tabTitle: 'Melio',
  settings: {
    Brand: { color: { enabled: true, flagName: 'organization-brand-color-selection' }, logo: { enabled: true } },
    supportEmail: 'support@melio.com',
    collaborator: {
      rolesOrderMapping: {
        ['melio-owner']: 1,
        ['melio-admin']: 2,
        ['melio-accountant']: 3,
        ['melio-contributor']: 4,
      },
    },
    fundingSourcePolicy: {
      'flex-account': {
        order: 0,
        fundingSourceGroup: 'flex-account',
        read: true,
        write: true,
      },
      'bank-account': {
        order: 1,
        fundingSourceGroup: 'bank-account',
        read: true,
        write: true,
      },
      credit: {
        order: 2,
        fundingSourceGroup: 'card',
        read: true,
        write: true,
      },
      debit: {
        order: 3,
        fundingSourceGroup: 'card',
        read: true,
        write: true,
      },
    },
    vex: {
      unilateral: {
        isEmailLoginEnabled: true,
        shouldSuggestJoinMelio: true,
        isNewUnilateral: true,
        suvcInformationLink: 'https://meliopayments.com/guides/single-use-virtual-cards/',
      },
    },
    subscriptions: {
      isGracePeriodSupported: true,
      claimSuggestedPlan: true,
      links: {
        seeAllFeatures: 'https://meliopayments.com/pricing/',
        learnMore: {
          smb: 'https://go.meliopayments.com/learnmore/smb',
          accountingClients: 'https://go.meliopayments.com/learnmore/clients',
          accountingFirmsV2: 'https://go.meliopayments.com/learnmore/accountants-new',
          accountingFirmsV1: 'https://go.meliopayments.com/learnmore/accountants',
        },
      },
      isPaymentFeeFailedGracePeriodEnabled: true,
    },
    vendor: {
      importVendorFieldsList: [
        'Vendor Business name',
        'Vendor Contact name',
        'Email',
        'Phone NO.',
        '1099 contractor',
        'Country',
        'Address line 1',
        'Address line 2',
        'City',
        'State',
        'ZIP code',
      ],
      vendorSelect: {
        createVendor: {
          showVendorDetailsForm: {
            enabled: true,
            flagName: 'platform-collect-vendor-address',
          },
          vendorDetailsFormType: 'Drawer',
        },
      },
    },
    settings: {
      options: [
        {
          type: 'company',
          items: [
            'profile-settings',
            'company-settings',
            'collaborators-setting',
            'notification-preferences',
            'workflows-setting',
            'accounting-software-sync',
            'tax-and-reports',
          ],
        },
        {
          type: 'payments',
          items: [
            'payment-methods-setting',
            'subscription-plans-setting',
            'billing-setting',
            'receiving-methods-settings',
          ],
        },
        {
          type: 'ar',
          items: ['receiving-methods-settings', 'invoice-items', 'invoice-settings', 'invoice-email-notifications'],
        },
        {
          type: 'support',
          items: ['support-setting'],
        },
      ],
    },
    guestPaymentFlow: {
      shouldGenerateDeepLinkToPaymentRequestPayDashboard: true,
    },
    InternationalFx: {
      enabled: true,
    },
    UploadVendorsCsv: {
      enabled: true,
      flagName: 'platform-upload-vendors-csv',
    },
    EnableInternationalUSDWise: {
      enabled: true,
      flagName: 'enable-international-usd-wise',
    },
    MarkAsPaid: {
      enabled: true,
    },
    ShowOnlyFastestDeliveryPreference: {
      enabled: true,
      flagName: 'platform-show-only-fastest-preference',
    },
    PushFxForInternationalOrgs: {
      flagName: 'push-fx-for-international-orgs',
      enabled: true,
    },
    File1099SettingsTaxAndReports: {
      flagName: 'platform-feature-1099-tax-and-reports-settings',
      enabled: true,
      assets: {
        zenworkTax1099Logo:
          'https://s3.us-east-1.amazonaws.com/meliopayments.com-static-assets/emails/zenwork-tax1099-logo.png',
        zenwork1099SeasonBanner:
          'https://s3.us-east-1.amazonaws.com/meliopayments.com-static-assets/emails/1099-season-banner.svg',
        zenworkSyncWithTax1099Banner:
          'https://s3.us-east-1.amazonaws.com/meliopayments.com-static-assets/emails/sync-with-tax-1099-banner.png',
      },
    },
    Import1099Contractors: {
      flagName: '1099-import-contractors',
      enabled: true,
    },
    File1099PreSeasonBanner: {
      flagName: '1099-pre-season-banners-dashboard',
      enabled: true,
    },
    File1099SeasonBanner: {
      flagName: '1099-season-banner-dashboard',
      enabled: true,
    },
    PlatformApprovalWorkflowsV2: {
      flagName: 'platform-approval-workflows-v2',
      enabled: true,
    },
    OutdatedSessionModal: {
      enabled: true,
      flagName: 'platform-outdated-session-modal',
    },
    BillingFeeSettingsForExternalAccountants: {
      flagName: 'enable-billing-fee-settings-for-external-accountant',
      enabled: true,
    },
    ExternalAccountantMandatoryPlanRestriction: {
      flagName: 'enable-external-accountant-mandatory-plan-restriction',
      enabled: true,
    },
    eoyCheck: {
      enabled: true,
      flagName: 'us-holidays-checks-partners',
      promoteFastCheck: true,
      link: 'https://melio.zendesk.com/hc/en-us/articles/6548872564508-USPS-potential-check-delivery-delays-during-the-holidays',
    },
    DeliveryMethodPublishNewEvents: {
      flagName: 'publish-delivery-method-new-events',
      enabled: true,
    },
    CollectVendorAddress: {
      flagName: 'platform-collect-vendor-address',
      enabled: true,
    },
    AccountantsPeriodicSummaryEmail: {
      flagName: 'accountants-digest-email-feature',
      enabled: true,
    },
    NewBusinessesSearch: {
      flagName: 'platform-new-businesses-search',
      enabled: true,
    },
    DisplayCancelPaymentsBannerInCancelFlowForClients: {
      flagName: 'display-cancel-payments-banner-in-cancel-flow-for-clients',
      enabled: true,
    },
    IsOrgOwesUsMoneyBannerEnabled: {
      flagName: 'is-org-owes-us-money-banner-enabled',
      enabled: true,
    },
    IsEddBannerEnabled: {
      flagName: 'is-edd-banner-enabled',
      enabled: true,
    },
    AccountantsDashboardV2Enabled: {
      flagName: 'accountants-dashboard-v2-enabled',
      enabled: true,
    },
    DuplicateVendors: {
      flagName: 'sync-deactivated-for-register',
      enabled: true,
    },
    CheckAllVendorsWhenMatching: {
      flagName: 'check-all-vendors-when-matching',
      enabled: true,
    },
    AutoMatchHighConfidenceVendors: {
      flagName: 'auto-match-high-confidence-vendors',
      enabled: true,
    },
    PaymentsTabFilters: {
      flagName: 'platform-payments-filters',
      enabled: true,
    },
    PaymentsTabFiltersPhase2: {
      flagName: 'platform-payments-filters-phase-2',
      enabled: true,
      supportedStatuses: {
        declined: true,
      },
    },
    BillsTabFiltersInfra: {
      flagName: 'platform-bills-filters',
      enabled: true,
    },
    BillsTabFiltersMenu: {
      flagName: 'platform-bills-filters-phase-2',
      enabled: true,
    },
    MidConfidence: {
      flagName: 'mid-confidence',
      enabled: true,
    },
    IsArFeeAbsorptionSettingsEnabled: {
      flagName: 'is-ar-fee-absorption-settings-enabled',
      enabled: true,
    },
    isContactSupportEnabled: false,
    ARPaymentsTab: {
      flagName: 'ar-payments-tab',
      enabled: true,
    },
    ARPayoutsTab: {
      flagName: 'ar-payouts-tab',
      enabled: true,
    },
    VendorCredits: {
      flagName: 'vendor-credits',
      enabled: true,
    },
  },
  links: melioLinks,
  translationsSrc: 'https://platform-static.meliopayments.com/assets/melio/messages.json',
  feedback: {
    triggers: {
      payment_success: {
        enabled: true,
        userSnapProjectId: 'aa15a60f-b879-43f4-8c8e-cd56bb1e1143',
      },
      first_time_invoice_creation: {
        enabled: true,
        userSnapProjectId: 'aa15a60f-b879-43f4-8c8e-cd56bb1e1143',
      },
      payment_received_marked_as_paid: {
        enabled: true,
        userSnapProjectId: 'aa15a60f-b879-43f4-8c8e-cd56bb1e1143',
      },
    },
  },
  requestCallback: {
    isEnabled: true,
    notes: { before: ['callFrom', 'emailVerification', 'didntGetEmailVerification'], after: ['callRecorded'] },
  },
  redirectWhenMissingToken: true,
  services: {
    singular: {
      sdkKey: 'melio_73571fea',
      sdkSecret: '4320915582436aa5e56298f04106ab03',
      productId: 'com.meliopayments.site.web',
    },
  },
  // https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/lambda-event-structure.html#response-event-fields
  extraHeaders: {
    'accept-ch': [
      { key: 'Accept-CH', value: 'sec-ch-ua-model,sec-ch-ua-platform-version,sec-ch-ua-full-version-list' },
    ],
    'permissions-policy': [
      {
        key: 'Permissions-Policy',
        value:
          'ch-ua-model=("https://sdk-api-v1.singular.net"),ch-ua-platform-version=("https://sdk-api-v1.singular.net"),ch-ua-full-version-list=("https://sdk-api-v1.singular.net")',
      },
    ],
  },
};

const prodMelioConfig: PartialMelioConfig = {
  services: {
    zendeskKey: '51fdfad6-7341-4cb2-8eb8-88ccece82bbc',
    zenwork: {
      clientId: 'UGNH8O92LSGG2GF2ZCQP8U4UX4Z6MD8A',
      melioRedirectUrl: 'https://app.melio.com/melio/import-contractors',
      formsDashboardUrl: 'https://web.tax1099.com/#/forms/listing/form1099',
      identityServiceUrl: 'https://identityservice.tax1099.com/connect/authorize',
    },
  },
};

export const melioPartialConfig: PartnerConfigByEnv = {
  default: defaultMelioConfig,
  dev: {},
  prod: prodMelioConfig,
};

export const melioConfig = extendDefaultConfig(melioPartialConfig);
